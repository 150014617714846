// Get from example integrate next.js with apollo
// https://github.com/kellenmace/apollo-client-cache-rehydration-in-next-js/blob/main/lib/apolloClient.ts
// https://github.com/vercel/next.js/blob/canary/examples/with-apollo/lib/apolloClient.js

import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import type { NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { useMemo } from 'react';
import merge from 'deepmerge';
import isEqual from 'lodash.isequal';

import { IS_SERVER } from 'utils/envs';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

function createApolloClient() {
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_API_URL,
    credentials: 'include',
  });

  const errorLink = onError(({ response }) => {
    console.log(response);
  });

  return new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache(),
    ssrMode: IS_SERVER,
  });
}

export default function initializeApollo(
  initialState: NormalizedCacheObject | null = null
) {
  const apolloInstance: ApolloClient<NormalizedCacheObject> =
    apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = apolloInstance.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    apolloInstance.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (IS_SERVER) return apolloInstance;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = apolloInstance;

  return apolloInstance;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: any
) {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);

  return store;
}
