import { ApolloProvider } from '@apollo/client';
import { useApollo } from 'apollo-client';

import { setupDayjsConfig } from 'utils/dates';

import 'styles/globals.css';

setupDayjsConfig();

function MyApp({ Component, pageProps }) {
  const apolloClient = useApollo(pageProps);

  return (
    <ApolloProvider client={apolloClient}>
      <Component {...pageProps} />
    </ApolloProvider>
  );
}

export default MyApp;
