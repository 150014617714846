import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import 'dayjs/locale/ru';

interface IDate {
  dayStart: string;
  monthName?: string;
  dayEnd?: string;
}

// установка всех настроек для dayjs (запускаем при старте приложения)
export const setupDayjsConfig = () => {
  dayjs.locale('ru');
  dayjs.extend(dayOfYear);
};

export const getDay = (dt?: string) => (dt ? dayjs(dt).format('DD') : '');

export const getMonthName = (dt?: string) =>
  dt ? dayjs(dt).format('DD MMMM').split(' ')[1] : '';

export const isDatesInOneMonth = (dateStart?: string, dateEnd?: string) =>
  dateStart && dateEnd && dayjs(dateStart).month() === dayjs(dateEnd).month();

// проверяет, входят ли даты в один день
export const isDatesInOneDay = (dateStart: string, dateEnd: string) =>
  dayjs(dateStart).dayOfYear() === dayjs(dateEnd).dayOfYear();

//отображаем дату мероприятия в разных форматах
export const getEventFormattedDate = (dateStart: string, dateEnd: string) => {
  const monthName = getMonthName(dateStart);
  const dayStart = getDay(dateStart);
  const dayEnd = getDay(dateEnd);

  let oneDayDate: IDate = null;
  let oneMonthDate: IDate = null;
  let twoMonthsDate: IDate = null;

  if (isDatesInOneDay(dateStart, dateEnd)) {
    oneDayDate = {
      dayStart,
      monthName,
    };
  }

  if (isDatesInOneMonth(dateStart, dateEnd)) {
    oneMonthDate = {
      dayStart,
      dayEnd,
      monthName,
    };
  } else {
    twoMonthsDate = {
      dayStart: dayjs(dateStart).format('DD.MM'),
      dayEnd: dayjs(dateEnd).format('DD.MM'),
    };
  }

  return {
    oneDayDate,
    oneMonthDate,
    twoMonthsDate,
  };
};
